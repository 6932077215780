import {useParams} from "react-router-dom";
import {useObjectVal} from "react-firebase-hooks/database";
import {ref} from 'firebase/database';
import {database} from '../firebase/app'
import {Alert, Card, Spin} from 'antd'
import Steps from "./Checkin/Steps";

export interface CheckInStep {
    text: string
    image: string
}

export interface ListingCheckInInfo {
    address: string
    steps: CheckInStep[]
}

export const getListingFirebaseRef = (listing: string) => ref(database, `checkin/listings/${listing}`);
export const getListingStepsFirebaseRef = (listing: string) => ref(database, `checkin/listings/${listing}/steps`);


const Checkin = () => {

    const {listing, cleaner} = useParams<{ listing: string, cleaner: string }>()

    const [
        listingCheckInInfo,
        loadingListingCheckInInfo,
        errorListingCheckInInfo] = useObjectVal<ListingCheckInInfo>(getListingFirebaseRef(listing!));


    if (loadingListingCheckInInfo) {
        return <Spin size={'large'}/>
    }
    if (errorListingCheckInInfo) {
        return <Alert type={'error'} message={'We encountered an error. Please refresh this page later.'}/>
    }
    if (!listingCheckInInfo) {
        return <Alert type={'error'} message={'We could not find this listing.'}/>
    }


    let steps = listingCheckInInfo.steps;

    return <div style={{gap: '.5rem', display: 'flex', flexDirection: "column"}}>
        <Card>
            <h1>
                Hey {cleaner}!
            </h1>
            <h2>
                Go To
            </h2>
            <h1>
                {listingCheckInInfo.address}
            </h1>
        </Card>
        <Steps steps={steps}/>

    </div>
}
export default Checkin;
