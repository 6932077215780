import React, {PropsWithChildren} from "react";
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "./firebase/app"
import {useAuthState} from "react-firebase-hooks/auth";
import {Button, Form, Input, notification} from "antd";


const Authenticate = (props: PropsWithChildren) => {
    const [user, loadingUser, errorUser] = useAuthState(auth);
    if (errorUser) {
        return <div>{errorUser.message}</div>
    }
    if (loadingUser) {
        return <div>loading...</div>
    }
    if (!user) {
        return <div>
            <Form
                layout={"inline"}
                name="basic"
                initialValues={{}}
                onFinish={async (values) => {
                    signInWithEmailAndPassword(auth, values.email, values.pass).catch(e => notification.error({message: e.message}))
                }}
            >
                <Form.Item
                    label="email"
                    name="email"
                    rules={[{required: true}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="pass"
                    name="pass"
                    rules={[{required: true}]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        login
                    </Button>
                </Form.Item>

            </Form>
        </div>
    }

    return <>{props.children}</>
}

export default Authenticate
