import {Card} from "antd";

const Steps = ({steps}: { steps: { text: string, image: string }[] }) => {
    return <div className={"steps"}>
        {steps.map((step, i) => <div key={i}>
            <Card cover={
                <img
                    alt={step.text}
                    src={step.image}
                />}>
                <Card.Meta description={step.text}/>
            </Card>
        </div>)}
    </div>
}
export default Steps;