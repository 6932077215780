import {auth} from '../firebase/app'
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth";
import {Form, Button, Spin, notification, Input} from "antd";
import {useEffect} from "react";

const Login = () => {
    const [signInWithEmailAndPassword,
        ,
        loading,
        error] = useSignInWithEmailAndPassword(auth)

    const onFinish = async ({email, password}: { email: string, password: string }) => {
        try {
            await signInWithEmailAndPassword(email, password);
        } catch (e: any) {
            notification.error({message: e.message})
        }
    };
    useEffect(() => {
        if (!error) {
            return
        }
        notification.error({message: error.message})
    }, [error])

    return <Spin spinning={loading}>
        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            initialValues={{remember: true}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{required: true, message: 'Please input your email!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{required: true, message: 'Please input your password!'}]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>
    </Spin>
}
export default Login;
