import {Card, Alert, notification, Spin} from "antd";
import {ref} from 'firebase/storage';
import {useUploadFile, useDownloadURL} from 'react-firebase-hooks/storage';
import {storage} from '../../firebase/app'
import {useEffect, useState} from "react";
import {StorageReference} from "@firebase/storage";
import {updatePassportUrl} from "../../guesty";

const UploadPassport = ({
                            isIsraeli,
                            reservationId,
                            onPassportUploaded
                        }: { reservationId: string, isIsraeli: boolean, onPassportUploaded: (url: string) => void }) => {

    const documentName = isIsraeli ? 'ID' : 'passport';

    const [uploadFile, uploading, , error] = useUploadFile();


    const [passportRef, setPassportRef] = useState<StorageReference>()
    const [passportDownloadUrl, loadingPassportDownloadUrl, errorPassportDownloadUrl] = useDownloadURL(passportRef);

    useEffect(() => {
        if (!passportDownloadUrl) {
            return;
        }
        updatePassportUrl(reservationId, passportDownloadUrl).then(() => {
            onPassportUploaded(passportDownloadUrl);
        }).catch(() => {
            notification.error({message: 'Error uploading file. Refresh the page and try again.'})
        })

    }, [onPassportUploaded, passportDownloadUrl, reservationId])

    const upload = async (file: File) => {
        const fileRef = ref(storage, `${reservationId}/${file.name}`);
        try {
            const result = await uploadFile(fileRef, file);
            if (!result) {
                notification.error({message: 'Error uploading file. Refresh the page and try again.'})
                return
            }
            setPassportRef(result.ref);


        } catch (e) {
            notification.error({message: 'Error uploading file. Refresh the page and try again.'})
        }
    }

    return <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <div>
            <Alert type={'warning'}
                   message={
                       <div style={{display:"flex",flexDirection:"column",gap:"10px",fontSize:"1.5rem"}}>
                           <div>Uploading {documentName} is a must in order to check in according to the Israeli law.</div>
                           <div style={{direction:"rtl"}}>חובה להעלות תמונה על פי חוק. כניסה לדירה לא תתאפשר ללא תמונה של תעודה מזהה.</div>
                       </div>
                   }/>

        </div>
        <Spin spinning={uploading || loadingPassportDownloadUrl}>
            <Card title={`Upload ${documentName} | יש להעלות תעודה מזהה`}>

<div>
    <div>
                    <label htmlFor="IDfile" style={{cursor:"pointer",padding:"10px",fontSize:"1.5rem",border:"solid 2px #ccc",background:"#f1f1f1",borderRadius:"4px"}}>Select Image  בחר תמונה</label>
    </div>   <div>

                <input id={"IDfile"} style={{visibility:"hidden",color:"transparent"}} type="file" onChange={async (e) => {
                    const file = e.target.files ? e.target.files[0] : undefined;
                    if (!file) {
                        return
                    }
                    await upload(file);
                }
                }/>   </div>
</div>

            </Card>
        </Spin>̵
        {(error || errorPassportDownloadUrl) && <div>
            <Alert type={'error'}
                   message={`Error uploading your file. Please refresh the page and try again.`}/>

        </div>}

    </div>
}

export default UploadPassport;
