const isDev = false;
let host = " https://us-central1-guestsv2.cloudfunctions.net/";
if (isDev) {
    host = "http://localhost:5000/guestsv2/us-central1/";
}

/*
* NEW FUNCTIONS MUST DO THIS TO WORK!!!
* * https://stackoverflow.com/questions/47511677/firebase-cloud-function-your-client-does-not-have-permission-to-get-url-200-fr
* *
 */

export const fetchReservationByConfirmationCode = async (confirmationCode: string) => {
    const url = new URL(`${host}fetchReservation`);
    url.searchParams.append('cc', confirmationCode);
    const res = await fetch(url.toString());
    if (!res.ok) {
        console.error(res);
        throw new Error('server error')
    }
    return await res.json()

};

export const approveTerms = async (reservationId: string) => {
    const url = new URL(`${host}approveTerms`);
    url.searchParams.append('rid', reservationId);
    const res = await fetch(url.toString());
    if (!res.ok) {
        console.error(res);
        throw new Error('server error')
    }
    return await res.text()
};


export const updatePlannedTimes = async (reservationId: string, plannedArrival: string, plannedDeparture: string) => {
    const url = new URL(`${host}updatePlannedTimes`);
    url.searchParams.append('rid', reservationId);
    url.searchParams.append('plannedArrival', plannedArrival);
    url.searchParams.append('plannedDeparture', plannedDeparture);

    const res = await fetch(url.toString());
    if (!res.ok) {
        console.error(res);
        throw new Error('server error')
    }
    return await res.text()

};


export const updatePassportUrl = async (reservationId: string, passportUrl: string) => {
    const url = new URL(`${host}passportUrlUpdate`);

    url.searchParams.append('rid', reservationId);
    url.searchParams.append('passportUrl', passportUrl);

    const res = await fetch(url.toString());
    if (!res.ok) {
        console.error(res);
        throw new Error('server error')
    }
    return await res.text()
};