import {useParams} from "react-router-dom";
import {useObjectVal} from "react-firebase-hooks/database";
import {set} from "firebase/database"
import {CheckInStep, getListingFirebaseRef, getListingStepsFirebaseRef, ListingCheckInInfo} from "./Checkin";
import {Button, Card, FloatButton, Modal, notification} from "antd";
import {useEffect, useState} from "react";
import {useTitle} from "ahooks";
import EditStep from "./CheckinModify/EditStep";
import Authenticate from "../Authenticate";


const removeAtIndex = (arr: any[], index: number) => {
    arr.splice(index, 1);
    return arr
}

const addAtIndex = (item: any, arr: any[], index: number) => {
    arr.splice(index, 0, item);
    return arr
}

const CheckinModify = () => {

    const {listing} = useParams<{ listing: string }>()
    useTitle("modify checkin")
    const listingRef = getListingFirebaseRef(listing!);
    const listingStepsRef = getListingStepsFirebaseRef(listing!);
    const [listingCheckInInfo] = useObjectVal<ListingCheckInInfo>(listingRef);

    const [modifiedSteps, setModifiedSteps] = useState<CheckInStep[]>([])
    const [editedStepIndex, setEditedStepIndex] = useState<number>()

    useEffect(() => {
        if (!listingCheckInInfo) {
            return
        }
        setModifiedSteps(listingCheckInInfo.steps)
    }, [listingCheckInInfo])

    return <Authenticate>
        <div>
            <FloatButton type={"primary"} onClick={async () => {
                try {
                    console.log({listingRef: listingRef.key, modifiedSteps})
                    await set(listingStepsRef, modifiedSteps)
                    notification.success({message:"updated!",placement:"bottomRight"})
                } catch (e: any) {
                    notification.error({message: e.message,placement:"bottomRight"})
                }
            }} shape={'square'} description={"Show saved changed to guests"}/>
            <Modal onCancel={() => setEditedStepIndex(undefined)}
                   destroyOnClose={true}
                   footer={<></>} open={editedStepIndex !== undefined}>
                {editedStepIndex && <>
                    <EditStep
                        defaultImage={modifiedSteps[editedStepIndex].image}
                        defaultText={modifiedSteps[editedStepIndex].text}
                        onChange={(image, text) => {
                            let newSteps = [...modifiedSteps]
                            newSteps = removeAtIndex(newSteps, editedStepIndex)
                            newSteps = addAtIndex({
                                text, image
                            }, newSteps, editedStepIndex)
                            setModifiedSteps(newSteps)
                            setEditedStepIndex(undefined)
                        }}/>
                </>}
            </Modal>
            <div className={"steps"}>
                {modifiedSteps.map((step, i) => <div key={i}>
                    <div style={{padding:"2rem"}}>
                        <Button onClick={()=>{
                            const newSteps=addAtIndex({text:"",image:""},[...modifiedSteps],i)
                            setModifiedSteps(newSteps)
                            setTimeout(()=>{ setEditedStepIndex(i)},150)


                        }} type={"primary"}>Add step here</Button>
                    </div>
                    <Card
                        actions={[
                            <Button type={"primary"} onClick={() => {
                                setEditedStepIndex(i)
                            }}>Edit</Button>,
                            <Button type={"primary"}

                                    onClick={() => {
                                        let newSteps = [...modifiedSteps]
                                        newSteps = removeAtIndex(newSteps, i)
                                        setModifiedSteps(newSteps)
                                    }}
                                    danger={true}>Delete</Button>,
                            <Button onClick={() => {
                                let newSteps = [...modifiedSteps]
                                const item = newSteps.at(i)
                                newSteps = removeAtIndex(newSteps, i)
                                newSteps = addAtIndex(item, newSteps, i - 1)
                                setModifiedSteps(newSteps)
                            }} disabled={i === 0}>Up</Button>,
                            <Button
                                onClick={() => {
                                    let newSteps = [...modifiedSteps]
                                    const item = newSteps.at(i)
                                    newSteps = removeAtIndex(newSteps, i)
                                    newSteps = addAtIndex(item, newSteps, i + 1)
                                    setModifiedSteps(newSteps)
                                }}
                                disabled={i === modifiedSteps.length - 1}>Down</Button>

                        ]}
                        cover={
                            <img
                                alt={step.text}
                                src={step.image}
                            />}>
                        <Card.Meta description={step.text}/>
                    </Card>
                </div>)}
            </div>
        </div>
    </Authenticate>
}
export default CheckinModify
