import React from 'react';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Checkin from "./App/Checkin";
import CheckinCleaner from "./App/CheckinCleaner";
import 'antd/dist/reset.css';
import {
    RecoilRoot,
} from 'recoil';
import Header from "./App/Header";
import AllCheckins from "./App/AllCheckins";
import CheckinModify from "./App/CheckinModify";
import HeaderModify from "./App/HeaderModify";

const router = createBrowserRouter([
    {
        path: "checkin/:listing/:confCode",
        element: <Checkin/>,
    },
    {
        path: "checkin-cleaner/:cleaner/:listing/",
        element: <CheckinCleaner/>,
    },
    {
        path: "checkin/header/modify",
        element: <HeaderModify/>,
    },
    {
        path: "checkin/:listing/modify",
        element: <CheckinModify/>,
    },
    {
        path: "all",
        element: <AllCheckins/>
    }
]);

function App() {
    return (
        <RecoilRoot>
            <div className={'app'}>

                <Header/>

                <main>
                    <RouterProvider router={router}/>
                </main>
            </div>
        </RecoilRoot>
    );
}

export default App;
