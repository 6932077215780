import {useObjectVal} from "react-firebase-hooks/database";
import {useAuthState} from "react-firebase-hooks/auth";
import {ref} from 'firebase/database';
import {database, auth} from '../firebase/app'
import {ListingCheckInInfo} from "./Checkin";
import {List, Drawer, Typography} from "antd"
import Login from "./Login";
import {useState} from "react";
import Steps from "./Checkin/Steps";
import {useTitle} from "ahooks";

export const getListingsFirebaseRef = ref(database, `checkin/listings`);

const AllCheckins = () => {
    const [data, loading, error] = useObjectVal<Record<string, ListingCheckInInfo>>(getListingsFirebaseRef)
    const [user, loadingUser] = useAuthState(auth)
    const [selectedCheckIn, setSelectedCheckIn] = useState<ListingCheckInInfo | null>(null)
    useTitle("all checkins")
    if (loading || loadingUser) {
        return <>Loading...</>
    }
    if (error) {
        return <>Error</>
    }
    if (!user) {
        return <Login/>
    }
    if (!data) {
        return <>no data</>
    }


    return <>
        <Drawer onClose={() => setSelectedCheckIn(null)} visible={Boolean(selectedCheckIn)}>
            {selectedCheckIn && <Steps steps={selectedCheckIn.steps}/>}

        </Drawer>
        <List
            bordered
            dataSource={Object.entries(data)}
            renderItem={([name, details]) => (
                <List.Item onClick={() => {
                    setSelectedCheckIn(details as ListingCheckInInfo)
                }}>
                    <Typography.Text underline={true}
                                     style={{fontSize: "2rem", cursor: "pointer"}}>{name}</Typography.Text>
                </List.Item>
            )}
        />
    </>
}
export default AllCheckins;
