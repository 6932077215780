import {useObjectVal} from "react-firebase-hooks/database";
import {ref, set} from "firebase/database";
import {database} from "../firebase/app";
import EditStep from "./CheckinModify/EditStep";
import {Button, Checkbox, notification, Spin} from "antd";
import {useEffect, useState} from "react";

const headerRef = ref(database, `headerMessage`);

interface HeaderInfo {
    shouldShow: boolean,
    image: string,
    text: string
}

const HeaderModify = () => {

    const [defaultHeaderInfo, loading] = useObjectVal<HeaderInfo>(headerRef);
    const [headerInfo, setHeaderInfo] = useState<HeaderInfo>();
    useEffect(() => {
        if (!defaultHeaderInfo) {
            return
        }
        setHeaderInfo(defaultHeaderInfo)
    }, [defaultHeaderInfo])
    return <Spin spinning={loading}>

        {headerInfo &&
            <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                <div>
                    <Checkbox onChange={(e) => {
                        setHeaderInfo({...headerInfo, shouldShow: e.target.checked})
                    }} defaultChecked={headerInfo.shouldShow}> Show to guests?</Checkbox>
                </div>
                <div>
                    <EditStep defaultImage={headerInfo.image} defaultText={headerInfo.text} onChange={(image, text) => {
                        setHeaderInfo({...headerInfo, text, image})
                    }}/>
                </div>
                <div>
                    <Button
                        onClick={async () => {
                            try {
                                await set(headerRef, headerInfo)
                                notification.success({message: "updated!"})
                            } catch (e: any) {
                                notification.error({message: e.message})
                            }
                        }}
                        type={"primary"} size={"large"}>Display new header to guests</Button>
                </div>
            </div>

        }

    </Spin>
}
export default HeaderModify
