import {Avatar, Button, Input, notification, Spin} from "antd";
import {ref, getDownloadURL} from "firebase/storage";
import {storage} from "../../firebase/app";
import {useUploadFile} from "react-firebase-hooks/storage";
import {v4 as uuidv4} from 'uuid';
import {useState} from "react";

const EditStep = ({
                      defaultImage,
                      defaultText,
                      onChange
                  }: { defaultImage: string, defaultText: string, onChange: (image: string, text: string) => void }) => {
    const [imageUrl, setImageUrl] = useState<string>(defaultImage)
    const [text, setText] = useState<string>(defaultText)
    const [uploadFile, uploading] = useUploadFile();
    const upload = async (file: File) => {
        const id = uuidv4()

        const fileRef = ref(storage, `checkins/${id}${file.name}`);
        try {
            const result = await uploadFile(fileRef, file);
            if (!result) {
                notification.error({message: 'Error uploading file. Refresh the page and try again.'})
                return
            }

            const url = await getDownloadURL(fileRef)
            setImageUrl(url)


        } catch (e) {
            notification.error({message: 'Error uploading file. Refresh the page and try again.'})
        }
    }

    return <>
        <Spin spinning={uploading}>

            <Avatar src={imageUrl} size={150} shape={"square"}/>
            <input type="file" onChange={async (e) => {
                const file = e.target.files ? e.target.files[0] : undefined;
                if (!file) {
                    return
                }
                await upload(file);
            }
            }/>


            <Input.TextArea defaultValue={text} onChange={e => setText(e.target.value)}/>

            <Button
                disabled={!imageUrl || !text}
                onClick={() => {
                    onChange(imageUrl, text)
                }}>Save New Image and Text</Button>
        </Spin>
    </>
}
export default EditStep
