import {Button, Spin, Tag, Card, Select, Space, notification} from "antd";
import {useState} from "react";
import {useRequest} from "ahooks";
import {updatePlannedTimes} from "../../guesty";

const ArrivalTimesForm = ({
                              reservationId,
                              isCheckInSoon,
                              onArrivalTimesSet
                          }: { reservationId: string, isCheckInSoon: boolean, onArrivalTimesSet: () => void }) => {

    const [eta, setEta] = useState();
    const [etd, setEtd] = useState();


    const {runAsync, loading} = useRequest(() => {
        if (!eta || !etd) {
            return Promise.reject(new Error('set times'));
        }
        return updatePlannedTimes(reservationId, eta, etd)
    }, {
        manual: true,
        onError: () => {
            notification.error({message: "We are having some trouble, please refresh the page."});
        },
        onSuccess: () => {
            onArrivalTimesSet()
        }
    })

    return <div>
        <Spin spinning={loading}>
            <Card>
                <Space direction={"vertical"} size={'large'}>
                    <h2>Check-in time</h2>
                    <Tag color="blue">🛬 Airport to apartment: around 1 hour</Tag>
                    <div>
                        <Space>
                            We will not arrive the apartment before:
                            <Select allowClear={true} onChange={value => setEta(value)} placeholder={"choose time"}>
                                <Select.Option value={"15:00"} key={"15:00"}>15:00</Select.Option>
                                <Select.Option value={"16:00"} key={"16:00"}>16:00</Select.Option>
                                <Select.Option value={"17:00"} key={"17:00"}>17:00</Select.Option>
                                <Select.Option value={"18:00"} key={"18:00"}>18:00</Select.Option>
                                <Select.Option value={"19:00"} key={"19:00"}>19:00</Select.Option>
                                <Select.Option value={"20:00"} key={"20:00"}>20:00</Select.Option>
                                <Select.Option value={"21:00"} key={"21:00"}>21:00</Select.Option>
                                <Select.Option value={"22:00"} key={"22:00"}>22:00</Select.Option>
                                <Select.Option value={"23:00"} key={"23:00"}>23:00</Select.Option>
                                <Select.Option value={"24:00"} key={"24:00"}>24:00</Select.Option>
                            </Select>
                        </Space>
                    </div>

                    <h2>Check-out time</h2>
                    <div>
                        <Space>
                            We will leave the apartment by:
                            <Select allowClear={true} onChange={value => setEtd(value)} placeholder={"choose time"}>
                                <Select.Option value={'6:00'} key={"6:00"}>6:00</Select.Option>
                                <Select.Option value={'7:00'} key={"7:00"}>7:00</Select.Option>
                                <Select.Option value={'8:00'} key={"8:00"}>8:00</Select.Option>
                                <Select.Option value={'9:00'} key={"9:00"}>9:00</Select.Option>
                                <Select.Option value={'10:00'} key={"10:00"}>10:00</Select.Option>
                                <Select.Option value={'11:00'} key={"11:00"}>11:00</Select.Option>
                            </Select>
                        </Space>
                    </div>
                    <div>
                        <Button onClick={() => {
                            runAsync().catch(e => console.log(e));
                        }
                        } disabled={!eta || !etd} type={"primary"} size={"large"}>Save</Button>
                    </div>
                </Space>
            </Card>
        </Spin>
        <Card>

            <h2>I need early check-in/late checkout</h2>
            {isCheckInSoon ? "Please set the times here as if it is not possible, and please ask us directly via email/chat and we will try and help!" : "We can only know if this is possible 3 days before check-in. Make sure to ask us by then."}


        </Card>
        <Card>
            <h2>I don't know yet my arrival time</h2>
            {isCheckInSoon ? "Try to figure it out or give us an estimate. Thanks." : "There is still time until your check-in date. Come back later and fill this info."}

        </Card>
    </div>

}
export default ArrivalTimesForm;