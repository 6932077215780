import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDxZJa4PJ55CoWTjsGNIxw76XzyZcWMEhY",
    authDomain: "guestsv2.firebaseapp.com",
    databaseURL: "https://guestsv2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "guestsv2",
    storageBucket: "guestsv2.appspot.com",
    messagingSenderId: "386670473739",
    appId: "1:386670473739:web:198b20300e6f62ffdacd0e"
};

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
