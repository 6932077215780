import logo from "../stk-logo.png";
import React from "react";
import {atom, useRecoilValue} from 'recoil'

export const guestInfoAtom = atom<{ fullName: string } | null>({
    key: 'guestInfo',
    default: null,
});

const Header = () => {
    const guestInfo = useRecoilValue(guestInfoAtom);
    return <header>
        <div className={'header__logo__cont'}>
            <img className={'header__logo'} src={logo} alt="stk"/>
        </div>
        <div style={{visibility: guestInfo ? 'visible' : 'hidden'}}>
            Shalom {guestInfo?.fullName}
        </div>
    </header>
}
export default Header;