import {Card, Spin, Checkbox, Button, notification, Space} from "antd"
import {useState} from "react";
import {useRequest} from "ahooks";
import {approveTerms} from "../../guesty";

const ContactApproval = ({
                             reservationId,
                             listing,
                             onApproved
                         }: { listing: string, reservationId: string, onApproved: () => void }) => {
    const [isApproved, setIsApproved] = useState(false);

    const {runAsync, loading} = useRequest(() => {
        return approveTerms(reservationId)
    }, {
        manual: true,
        onError: () => {
            notification.error({message: "We are having some trouble, please refresh the page."});
        },
        onSuccess: () => {
            onApproved()
        }
    })

    return <Spin spinning={loading}>
        <Card className="contact-approval" title={"Rental Terms"}>
            <Space direction={"vertical"}>
                <ul>
                    <li>
                        The tenant is responsible for taking good care of the furniture and appliances. The tenant will
                        pay
                        for
                        any repair or replacement if the
                        equipment was damaged during their stay.
                    </li>
                    <li>
                        Subletting is not allowed. The tenant agrees not to assign or sublet the property.
                    </li>
                    <li>
                        The tenant should keep the property in good condition.
                    </li>
                    <li>
                        Smoking is not allowed inside the property.
                    </li>
                    <li>
                        Parties are not allowed.
                    </li>
                    <li>
                        {listing==="hayarkon96" ? "Pets are not allowed.": "Pets are allowed."}
                    </li>
                    <li>
                        The tenants agree not to use the property in a way to disturb the peace in the surroundings or
                        environments.
                    </li>
                    <li>
                        The tenant's personal property is not covered in the insurance purchased by the landlord against
                        loss,
                        theft, and negligence.
                    </li>
                    <li>
                        The agreement can only be changed or modified with the written consent or permission from both
                        the
                        landlord and the tenant
                    </li>
                </ul>
                <div>
                    <Checkbox onChange={e => setIsApproved(e.target.checked)} checked={isApproved}>I Agree</Checkbox>
                </div>
                <div>
                    <Button onClick={() => {
                        runAsync().catch(e => console.error(e));
                    }} type={"primary"} disabled={!isApproved}>Next</Button>
                </div>
            </Space>
        </Card>
    </Spin>
}
export default ContactApproval;
